import { defineStore } from "pinia";
import { useAmplitudeStore } from "./amplitude";
export var useUserStore = defineStore("user", function () {
  var ampliStore = useAmplitudeStore();
  var user = ref(null);

  // Load from localStorage on init
  var stored = localStorage.getItem("user");
  if (stored) {
    user.value = JSON.parse(stored);
  }

  // Persist to localStorage and notify Amplitude
  watchEffect(function () {
    if (user.value) {
      localStorage.setItem("user", JSON.stringify(user.value));
      ampliStore.identify(user.value); // track in analytics
    } else {
      localStorage.removeItem("user");
    }
  });
  return {
    user: user
  };
});