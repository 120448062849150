import { render, staticRenderFns } from "./PersonLink.vue?vue&type=template&id=6259eea1"
import script from "./PersonLink.vue?vue&type=script&lang=js"
export * from "./PersonLink.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Icon/Icon.vue').default,Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,Chip: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/Chip/Chip.vue').default})
