
import NavigationControls from "./components/NavigationControls.vue";
import SettingsNavigationControls from "./components/SettingsNavigationControls.vue";
import EstaidMap from "../components/map/EstaidMap.vue";

import { getLoginMessage } from "~/helpers/login-message-helpers.js";
import UpsertPreferences from "~/graphql/User/UpsertPreferences.gql";

import { mapWritableState, mapState } from "pinia";
import { useChromeStore } from "~/composables/stores/chrome";
import { useSearchStore } from "~/composables/stores/search";
import { useUserStore } from "~/composables/stores/user";

import { useAuth0 } from "~/composables/useAuth0";

export default {
  name: "Default",


  components: {
    EstaidMap,
    NavigationControls,
    SettingsNavigationControls,
  },

  data() {
    return {
      auth0: useAuth0(),
      isReady: false,
      maprender: true,
      errorBanner: true,
      dragIsMoving: false,
      dragPositionX: undefined,
      welcomeBack: false,
      panelFocus: "middle",
      panelFocusBlockAll: false,
      mapHighlight: false,
      lastExploreRoute: {
        name: "index",
        params: "",
      },
      lastMyEstaidRoute: {
        name: "my-estaid-lists",
        params: "",
      },
      loginMessage: undefined,
      route: undefined,
      routeName: undefined,
    };
  },

  computed: {
    thirdPartyError() {
      return this.$isFeatureSupported("forceThirdPartyError") || this.$store.getters["errors/isThirdPartyError"];
    },

    numberOfAppliedFilters() {
      return this.$store.getters["explore/getNumberOfAppliedFilters"];
    },

    mapOmit: {
      get() {
        return this.$store.getters["mapstate/mapOmit"];
      },

      set(v) {
        this.$store.commit("mapstate/mapOmit", v);
      },
    },

    ...mapWritableState(useChromeStore, ["splitPercentageBarOmit"]),

    ...mapWritableState(useSearchStore, { searchStoreSearchTerm: "searchTerm" }),

    ...mapState(useUserStore, ["user"]),
  },

  watch: {
    $route: {
      deep: true,
      handler(current) {
        this.routeName = current.name;
        this.route = current;

        this.updateMapOmit();
        this.updateSplitPercentageBarOmit();
      },
    },

    routeName() {
      if (this.$route.name.includes("explore")) {
        this.lastExploreRoute.name = this.$route.name;
        this.lastExploreRoute.params = this.$route.params;
      } else if (this.$route.name.includes("my-estaid")) {
        this.lastMyEstaidRoute.name = this.$route.name;
        this.lastMyEstaidRoute.params = this.$route.params;
      }
    },

    accessGroup: function (next, prev) {
      // If the access group changes, we need to refetch the data
      // so we refresh the current route
      if (next && next !== prev) {
        this.$router.go();
      }
    },
  },

  async beforeCreate() {
    const locale = await this.$userPreferences.getKey("preferenceNamespaceLocale", "locale");

    this.$moment.locale(locale);
    this.$i18n.locale = locale;
  },

  mounted() {
    //temporary fix to remove legacy localStorage tokens from users.
    if (localStorage.getItem("auth0-token")) {
      localStorage.removeItem("auth0-token");
    }

    this.auth0.authenticate().finally(() => {
      this.isReady = true;
    });

    this.updateMapOmit();
    this.updateSplitPercentageBarOmit();

    this.$store.dispatch("mapstate/loadInitialState");

    this.routeName = this.$route.name;
    this.route = this.$route;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      // this.dialog = true;
    }

    if (this.$route.query?.url) {
      this.welcomeBack = true;
    }

    window.addEventListener("keydown", (event) => {
      if (event.altKey && event.code === "KeyF") {
        setTimeout(() => {
          document.querySelectorAll(".chrome-container-search input")[0].focus();
        }, 0);
      } else if (event.shiftKey && (event.code === "ArrowLeft" || event.code === "ArrowRight")) {
        // TO-DO: cllpse: this overrides text selection in all TextField's
        // const elements = ["start", "middle", "end"];
        // const keyAction =  event.code === "ArrowLeft" ? 1 : -1;
        // const curIndex = elements.indexOf(this.panelFocus);
        // const newIndex = Math.min(Math.max(curIndex+keyAction, 0), 2);
        // this.panelFocus = elements[newIndex];
      }

      return false;
    });
    this.$store.commit("mapstate/setInitialLoad", !this.$route.params?.id);
  },

  methods: {
    updateMapOmit() {
      const rn = this.$route.name;

      this.mapOmit = rn == "index" || rn.indexOf("settings") != -1 || rn.indexOf("template-builder") != -1;
    },

    updateSplitPercentageBarOmit() {
      this.splitPercentageBarOmit = this.$route.name == "explore";
    },

    autocompleteChange(visible) {
      this.panelFocusBlockAll = visible;
    },

    sanitizeString(string) {
      if (string == null || typeof string != "string") return "";

      return string.replace(", , ", ", ").replace(/\s{2,}/g, " ");
    },

    goToLastAddress() {
      this.welcomeBack = false;

      this.$router.push({
        name: "explore-property-id-overview",
        params: { id: this.$route.query.url },
      });
    },

    goToExplore() {
      this.clearState();
      this.$map.then((map) => {
        map.fire("draw.deleteAllDrawings");
      });
      this.$router.push({ name: "explore" });
      this.$amplitude.navigateToExplore();
    },

    goToHome() {
      this.$router.push({ name: "index" });
    },

    goToLists() {
      this.clearState();
      if (this.routeName.includes("my-estaid-lists")) {
        this.$map.then((map) => {
          map.fire("draw.deleteAllDrawings");
        });

        this.$router.push({ name: "my-estaid-lists" });

        this.lastMyEstaidRoute = {
          name: "my-estaid-lists",
          params: "",
        };
      } else {
        this.$router.push(this.lastMyEstaidRoute);
      }
    },

    goToSettings() {
      this.clearState();
      this.$router.push("/settings/");
    },

    goToTemplateBuilder() {
      this.clearState();
      this.$router.push("/template-builder/");
    },

    goToAnalytics() {
      this.clearState();
      this.$router.push({ name: "analytics" });
    },

    clearState() {
      this.$store.commit("explore/RESET_STATE");
      this.$store.dispatch("search/clearState");
      this.searchStoreSearchTerm = "";
    },

    closeWelcomeBack() {
      this.welcomeBack = false;
    },

    async checkLoginMessages(orgId) {
      this.loginMessage = await getLoginMessage(orgId, this.$isFeatureSupported("personSearchLoginWarningTest"), this);

      if (this.loginMessage) {
        setTimeout(() => {
          this.$refs.dialog?.showModal();
        }, 1000);
      }
    },

    advancedFiltersActive() {
      this.panelFocus = "middle";
    },

    async loginMessageDismissed() {
      const prefs = { ...this.me.preferences?.blob } || {};
      if (!prefs[this.loginMessage.preferenceNamespace]) {
        prefs[this.loginMessage.preferenceNamespace] = {};
      }

      prefs[this.loginMessage.preferenceNamespace][this.loginMessage.preferenceKey] = true;

      await this.$apollo.mutate({
        mutation: UpsertPreferences,
        variables: {
          input: {
            blob: prefs,
          },
        },
      });

      this.$apollo.queries.me.refetch();
    },
  },
};
