import PropertyOverview from "~/graphql/Property/PropertyOverview.gql";
import PropertyHeader from "~/graphql/Property/PropertyHeader.gql";
import PropertyGeometry from "~/graphql/Property/PropertyGeometry.gql";
import PropertyOwners from "~/graphql/Property/PropertyOwners.gql";
import PropertyAreas from "~/graphql/Property/PropertyAreas.gql";
import PropertyCommercial from "~/graphql/Property/PropertyCommercial.gql";
import PropertyDocuments from "~/graphql/Property/PropertyDocuments.gql";
import PropertyDlrDocuments from "~/graphql/Property/PropertyDlrDocuments.gql";
import PropertyCondos from "~/graphql/Property/PropertyCondos.gql";
import PropertyEasements from "~/graphql/Property/PropertyEasements.gql";
import PropertyTransactions from "~/graphql/Property/PropertyTransactions.gql";
import PropertyCondosByBfeNumber from "~/graphql/Property/PropertyCondosByBfeNumber.gql";
import PropertyCondosBfe from "~/graphql/Property/PropertyCondosBfe.gql";
import PropertyConstructionCase from "~/graphql/Property/PropertyBuildingCase.gql";
import PropertyRentalLevels from "~/graphql/Property/PropertyRentalLevels.gql";

import { mapBuildings, mapPlots, mapTechnicalFacilities, mapUnits } from "./apollo-utility-helpers";

const daSearch = new Intl.Collator("da", {
  numeric: true,
  sensitivity: "base",
});

export const getPropertyHeaderQuery = function () {
  return {
    query: PropertyHeader,
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyGeometryQuery = function () {
  return {
    query: PropertyGeometry,
    error(error) {
      console.error("getPropertyGeometryQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyOverviewQuery = function () {
  return {
    query: PropertyOverview,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyOverviewQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyOwnersQuery = function () {
  return {
    query: PropertyOwners,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyOwnersQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyTransactionsQuery = function () {
  return {
    query: PropertyTransactions,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyTransactionsQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyAreaQuery = function () {
  return {
    query: PropertyAreas,
    update(orgData) {
      const data = structuredClone(orgData);

      if (data.propertyByBFENumber.plots) {
        data.propertyByBFENumber.plots = mapPlots(data.propertyByBFENumber.plots);

        data.propertyByBFENumber.buildings = data.propertyByBFENumber.plots
          ?.flatMap((x) => mapBuildings(x.buildings, `${x.matrikelNumber}, ${x.ownersGuildName}`))
          ?.filter((x) => !!x);

        data.propertyByBFENumber.units = data.propertyByBFENumber.plots
          ?.flatMap((x) => x.buildings?.flatMap((y) => mapUnits(y.units, `${x.matrikelNumber}, ${x.ownersGuildName}`, y.buildingNumber)))
          ?.filter((x) => !!x);

        data.propertyByBFENumber.technicalFacilities = data.propertyByBFENumber.plots
          ?.flatMap((x) => mapTechnicalFacilities(x.technicalFacilities))
          ?.filter((x) => !!x);
      }

      if (data.propertyByBFENumber.buildingOnForeignPlot) {
        data.propertyByBFENumber.buildings = mapBuildings(data.propertyByBFENumber.buildingOnForeignPlot.buildings, null)?.filter((x) => !!x);

        data.propertyByBFENumber.units = data.propertyByBFENumber.buildingOnForeignPlot.buildings
          ?.flatMap((y) => mapUnits(y.units, null, y.buildingNumber))
          ?.filter((x) => !!x);

        data.propertyByBFENumber.technicalFacilities = mapTechnicalFacilities(data.propertyByBFENumber.buildingOnForeignPlot.technicalFacilities)?.filter(
          (x) => !!x
        );
      }

      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyAreaQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyCondosByBfeNumberQuery = function () {
  return {
    query: PropertyCondosByBfeNumber,
    update(orgData) {
      const data = structuredClone(orgData);

      data.condosByBFENumber = data.condosByBFENumber
        ?.map((x) => {
          const valuation = x.valuations?.sort((a, b) => b.year - a.year)[0];
          x.usageText = valuation ? valuation.usageText : x.usageText ? x.usagetext : null;
          x.valuation = valuation?.propertyValuation;
          x.owners?.sort((a, b) => daSearch.compare(a?.name || "", b?.name || ""));
          x.ownersString = x.owners?.filter((owner) => owner != null).reduce((acc, ownr) => acc + ownr.name, "");
          return x;
        })
        .filter((x) => !!x);
      return data.condosByBFENumber;
    },
    error(error) {
      console.error("getPropertyCommercialQuery failed:", error);
    },
    variables: getQueryVariables,
  };
};

export const getPropertyCommercialQuery = function () {
  return {
    query: PropertyCommercial,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyCommercialQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyConstructionCaseQuery = function () {
  return {
    query: PropertyConstructionCase,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyConstructionCase failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyDocumentsQuery = function () {
  return {
    query: PropertyDocuments,
    update(orgData) {
      const data = structuredClone(orgData);

      if (data.propertyByBFENumber.danishLandRegister != null) {
        data.propertyByBFENumber.danishLandRegister.charges = data.propertyByBFENumber.danishLandRegister?.charges?.map((x) => {
          x.creditorsString = x.creditors?.map((x) => x.name).join("; ");
          x.debitorsString = x.debitors?.map((x) => x.name).join("; ");
          return x;
        });
      }
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyDocumentsQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyDlrDocuments = function () {
  return {
    query: PropertyDlrDocuments,
    variables: {
      bfeNumber: parseInt(this.$store.getters["search/bfeNumber"], 10),
    },
    error(error) {
      console.error("getPropertyDlrDocumentsQueryWithCondos failed:", error);
    },
    skip: getSkipParameter,
  };
};

export const getPropertyCondosQuery = function () {
  return {
    query: PropertyCondos,
    update(orgData) {
      const data = structuredClone(orgData);

      if (!data.condosByBFENumber || data.condosByBFENumber.length === 0) {
        return null;
      }
      let newArr = [];
      let totalArea = 0;
      let totalUnits = data.condos?.length ?? 0;

      data.condosByBFENumber.forEach((condo) => {
        if (condo.unit && condo.unit.totalArea) {
          totalArea += condo.unit.totalArea;
        }
        if (condo.owners) {
          condo.owners
            .filter((owner) => owner != null)
            .forEach((owner) => {
              let unit = {
                unit: condo.unit,
                bfeNumber: condo.bfeNumber,
                shareRate: condo.shareRate,
                share: owner.share,
                status: condo.status,
              };

              let indexOfExistingOwner = newArr
                .map(function (e) {
                  if (e && e.name) {
                    return e.name;
                  } else {
                    return;
                  }
                })
                .indexOf(owner ? owner.name : null);

              if (indexOfExistingOwner >= 0) {
                newArr[indexOfExistingOwner].units.push(unit);
                return;
              } else {
                let stitchOwner = Object.assign(owner);
                stitchOwner.units = [];
                stitchOwner.units.push(unit);
                newArr.push(stitchOwner);
              }
            });
        }
        if (!condo.owners) {
          let unit = {
            unit: condo.unit,
            bfeNumber: condo.bfeNumber,
            shareRate: condo.shareRate,
            share: 0,
            status: condo.status,
          };
          let stitchOwner = {
            statusText: "gældende",
            units: [],
            id: data.condosByBFENumber.indexOf(condo),
            person: { name: "-" },
          };
          stitchOwner.units.push(unit);
          newArr.push(stitchOwner);
        }
      });

      newArr.forEach((condo) => {
        condo.totalArea = totalArea;
        condo.totalUnits = totalUnits;
      });
      return newArr;
    },
    error(error) {
      console.error("getPropertyCondosQuery failed:", error);
    },
    variables: getQueryVariables,

    skip: getSkipParameter,
  };
};

export const getPropertyCondosBfeQuery = function () {
  return {
    query: PropertyCondosBfe,
    update(orgData) {
      const data = structuredClone(orgData);

      return data.condosByBFENumber.map((condo) => {
        condo.type = "unit";
        return condo;
      });
    },
    error(error) {
      console.error("getPropertyCondosBfeQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyEasementsQuery = function () {
  return {
    query: PropertyEasements,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyEasementsQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

export const getPropertyRentalLevelsQuery = function () {
  return {
    query: PropertyRentalLevels,
    update(data) {
      return data.propertyByBFENumber;
    },
    error(error) {
      console.error("getPropertyRentalLevelsQuery failed:", error);
    },
    variables: getQueryVariables,
    skip: getSkipParameter,
  };
};

// Variables
export const getQueryVariables = function queryVariables() {
  return {
    bfeNumber: parseInt(this.$store.getters["search/bfeNumber"], 10),
  };
};

export const getSkipParameter = function skipParameter() {
  return !this.$store.getters["search/bfeNumber"];
};
