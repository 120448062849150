import axios from "axios";
import PropertyHeader from "~/graphql/Property/PropertyHeader.gql";
import PlotByPoint from "~/graphql/Map/PlotByPoint.gql";
import bbox from "@turf/bbox";

export const isStreetviewAvailable = async function (lat, lng) {
  const basePath = "https://maps.googleapis.com/maps/api/streetview/metadata?parameters";
  const params = {
    location: `${lat},${lng}`,
    key: "AIzaSyB49WQ24JghRUZy89EAr8DfawFh_0MawWk",
  };
  const RESULT_OK = "OK";

  const resp = await axios.get(basePath, { params: params });

  return resp.data.status === RESULT_OK;
};

export const fitToGeoJsonBounds = function (polygons) {
  const bounding = bbox(polygons);

  if (bounding.includes(Infinity) || bounding.includes(NaN)) {
    return;
  }

  this.$map.then((map) => {
    try {
      map?.fitBounds(bounding, {
        maxZoom: 17,
        padding: 150,
        offset: [0, 0],
        animate: this.$store.getters["mapstate/getInitialLoad"],
      });
    } catch (e) {
      // Unhandled
    }
  });

  this.$store.commit("mapstate/setInitialLoad", true);
};

export const easeToDefault = function () {
  this.$map.then((map) => {
    map?.easeTo({
      center: [11, 56],
      zoom: 6,
      duration: 3000,
    });
  });
};

export const onClickPropertyPin = async function (event) {
  this.$store.commit("mapstate/setPopupLoading", true);
  const map = await this.$map;

  if (tryZoomOnCluster.call(this, map, event)) {
    return;
  }

  this.$store.commit("mapstate/setPopupOpen", false);
  const pin = map.queryRenderedFeatures(event.point)[0];
  if (pin && pin.properties && pin.properties.bfe) {
    const plotByBfe = await this.$apollo.query({
      query: PropertyHeader,
      fetchPolicy: "network-only",
      variables: {
        bfeNumber: pin.properties.bfe,
      },
    });

    const popup = {
      position: pin.geometry.coordinates,
      type: "PropertyWindow",
      data: plotByBfe.data.propertyByBFENumber,
      loading: false,
      offset: null,
    };

    this.$nextTick(() => {
      this.$store.dispatch("mapstate/setPopup", popup);
      this.$store.commit("mapstate/setPopupOpen", true);
    });
  } else {
    // handle msga pins
    // Continue finding the property
    this.$store.commit("mapstate/setPopupOpen", false);
    this.$nextTick(() => {
      this.$store.commit("mapstate/setPopupPosition", [event.lngLat.lng, event.lngLat.lat]);
      this.$store.commit("mapstate/setPopupType", "PropertyWindow");
      this.$store.commit("mapstate/setPopupLoading", true);
      this.$store.commit("mapstate/setPopupOpen", true);
    });
    let plotByPoint = await this.$apollo.query({
      query: PlotByPoint,
      fetchPolicy: "network-only",
      variables: {
        lat: event.lngLat.lat,
        lng: event.lngLat.lng,
      },
    });
    const popup = {
      position: [event.lngLat.lng, event.lngLat.lat],
      type: "PropertyWindow",
      data: plotByPoint.data?.plotByPoint?.property || null,
      loading: false,
      offset: null,
    };
    this.$store.dispatch("mapstate/setPopup", popup);
    this.$store.commit("mapstate/setPopupLoading", false);
  }

  return false;
};

export const onClickMatrikelPolygon = async function (event) {
  // Checking if need to ignore click when layers are on top
  const map = await this.$map;
  const clickThrough = ["plot_polygon_matrikel_numbers", "msga_property_pins"];
  const features = map.queryRenderedFeatures(event.point);
  let x = 0;
  while (x <= features.length) {
    if (features[x].layer.id === "plot_polygon_fills") {
      break;
    } else if (clickThrough.includes(features[x].layer.id)) {
      x++;
    } else {
      return;
    }
  }
  // Continue finding the property
  this.$store.commit("mapstate/setPopupOpen", false);
  this.$nextTick(() => {
    this.$store.commit("mapstate/setPopupPosition", [event.lngLat.lng, event.lngLat.lat]);
    this.$store.commit("mapstate/setPopupType", "PropertyWindow");
    this.$store.commit("mapstate/setPopupLoading", true);
    this.$store.commit("mapstate/setPopupOpen", true);
  });
  let plotByPoint = await this.$apollo.query({
    query: PlotByPoint,
    fetchPolicy: "network-only",
    variables: {
      lat: event.lngLat.lat,
      lng: event.lngLat.lng,
    },
    errorPolicy: "ignore",
  });
  const popup = {
    position: [event.lngLat.lng, event.lngLat.lat],
    type: "PropertyWindow",
    data: plotByPoint.data?.plotByPoint?.property || null,
    loading: false,
    offset: null,
  };
  this.$store.dispatch("mapstate/setPopup", popup);
};

export const onClickBuildingOrFacilityPin = async function (event) {
  const map = await this.$map;
  if (tryZoomOnCluster.call(this, map, event)) {
    return;
  }
  const pin = map.queryRenderedFeatures(event.point)[0];
  // Using .call(this, _) syntax to bind call to vue component scope
  if (pin.properties.type == "building") {
    onClickBuildingPin.call(this, event);
  } else if (pin.properties.type == "facility") {
    onClickFacilityPin.call(this, event);
  } else if (pin.properties.type == "condo") {
    onClickPropertyPin.call(this, event);
  } else if (pin.properties.type == "buildingOnForeignPlot") {
    onClickPropertyPin.call(this, event);
  }
};

const onClickBuildingPin = function (event) {
  this.$map.then((map) => {
    const popup = {
      position: map.queryRenderedFeatures(event.point)[0].geometry.coordinates,
      type: "BuildingWindow",
      id: map.queryRenderedFeatures(event.point)[0].properties.id,
      data: null,
    };
    this.$store.commit("mapstate/setPopupOpen", false);
    this.$nextTick(() => {
      this.$store.dispatch("mapstate/setPopup", popup);
    });
  });
};

const onClickFacilityPin = function (event) {
  this.$map.then((map) => {
    const id = map?.queryRenderedFeatures(event.point)[0].properties.id;
    const data = this.$apolloProvider.clients.defaultClient.cache.data.data[`TechnicalFacility:${id}`];
    const popup = {
      position: map?.queryRenderedFeatures(event.point)[0].geometry.coordinates,
      type: "FacilityWindow",
      id: null,
      data,
      // Todo: luppilau - When updating apollo, swap with real method
    };
    this.$store.commit("mapstate/setPopupOpen", false);
    this.$nextTick(() => {
      this.$store.dispatch("mapstate/setPopup", popup);
    });
  });
};

const tryZoomOnCluster = function (map, event) {
  const pin = map.queryRenderedFeatures(event.point)[0];
  if (pin.properties.cluster) {
    map.getSource(pin.source).getClusterExpansionZoom(pin.properties.cluster_id, (err, zoom) => {
      if (err) return;
      map.easeTo({
        center: pin.geometry.coordinates,
        zoom: zoom,
      });
    });
    return true;
  } else if (pin.properties.point_count > 1) {
    map.easeTo({
      center: pin.geometry.coordinates,
      zoom: map.getZoom() + 1,
    });
    return true;
  }
  return false;
};
