
export default {
  props: {
    company: {
      type: Object,
      required: true,
    },

    renderAsChip: {
      type: Boolean,
      default: false,
    },

    trackingOrigin: {
      type: String,
      default: null,
    },

    // Override copy props
    copyType: {
      type: String,
      default: "body",
    },

    copySize: {
      type: String,
      default: "medium",
    },

    includeShare: {
      type: Boolean,
      default: false,
    },

    share: {
      type: String,
      default: "",
    },
  },

  methods: {
    getRoute() {
      return {
        name: "explore-company-id-overview",
        params: { id: this.company.cvr || this.company.cvrNumber },
      };
    },

    track(){
      this.$amplitude.track({ event_type: "Company lookup", event_properties: {
        route: this.trackingOrigin,
        cvr: this.company.cvr || this.company.cvrNumber
      }});
    },

    getChipIconId(){
      switch (this.company.type) {
        case "COMPANY":
        default:
          return "icon-company-tt"

        case "ADMINISTRATOR":
          return "icon-key-tt"
      }
    }
  },
};
