import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2161b8d6 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _168d2bdc = () => interopDefault(import('../pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _cad79e9c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _1bcba93d = () => interopDefault(import('../pages/my-estaid.vue' /* webpackChunkName: "pages/my-estaid" */))
const _17d110f4 = () => interopDefault(import('../pages/my-estaid/lists/index.vue' /* webpackChunkName: "pages/my-estaid/lists/index" */))
const _bfecb924 = () => interopDefault(import('../pages/my-estaid/lists/_id.vue' /* webpackChunkName: "pages/my-estaid/lists/_id" */))
const _2c20a960 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _de57cf96 = () => interopDefault(import('../pages/template-builder/index.vue' /* webpackChunkName: "pages/template-builder/index" */))
const _50c6f8ea = () => interopDefault(import('../pages/explore/advanced/index.vue' /* webpackChunkName: "pages/explore/advanced/index" */))
const _a6c170e2 = () => interopDefault(import('../pages/template-builder/template-data.js' /* webpackChunkName: "pages/template-builder/template-data" */))
const _4b037f06 = () => interopDefault(import('../pages/template-builder/helpers/template-helpers.js' /* webpackChunkName: "pages/template-builder/helpers/template-helpers" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5afd0fb2 = () => interopDefault(import('../pages/explore/company/_id.vue' /* webpackChunkName: "pages/explore/company/_id" */))
const _68552447 = () => interopDefault(import('../pages/explore/company/_id/key-figures/index.vue' /* webpackChunkName: "pages/explore/company/_id/key-figures/index" */))
const _acfae12e = () => interopDefault(import('../pages/explore/company/_id/overview/index.vue' /* webpackChunkName: "pages/explore/company/_id/overview/index" */))
const _74f9af56 = () => interopDefault(import('../pages/explore/company/_id/ownership/index.vue' /* webpackChunkName: "pages/explore/company/_id/ownership/index" */))
const _503936ee = () => interopDefault(import('../pages/explore/company/_id/portfolio/index.vue' /* webpackChunkName: "pages/explore/company/_id/portfolio/index" */))
const _24862dfd = () => interopDefault(import('../pages/explore/company/_id/stakeholders/index.vue' /* webpackChunkName: "pages/explore/company/_id/stakeholders/index" */))
const _5ddff5c5 = () => interopDefault(import('../pages/explore/company/_id/transactions/index.vue' /* webpackChunkName: "pages/explore/company/_id/transactions/index" */))
const _57c37866 = () => interopDefault(import('../pages/explore/person/_id.vue' /* webpackChunkName: "pages/explore/person/_id" */))
const _4cd19a69 = () => interopDefault(import('../pages/explore/person/_id/index.vue' /* webpackChunkName: "pages/explore/person/_id/index" */))
const _b6a1af96 = () => interopDefault(import('../pages/explore/person/_id/overview/index.vue' /* webpackChunkName: "pages/explore/person/_id/overview/index" */))
const _2fe9a909 = () => interopDefault(import('../pages/explore/person/_id/ownership/index.vue' /* webpackChunkName: "pages/explore/person/_id/ownership/index" */))
const _2a57acde = () => interopDefault(import('../pages/explore/person/_id/relations/index.vue' /* webpackChunkName: "pages/explore/person/_id/relations/index" */))
const _4c3b4891 = () => interopDefault(import('../pages/explore/person/_id/transactions/index.vue' /* webpackChunkName: "pages/explore/person/_id/transactions/index" */))
const _5336ff66 = () => interopDefault(import('../pages/explore/property/_id.vue' /* webpackChunkName: "pages/explore/property/_id" */))
const _0f43e169 = () => interopDefault(import('../pages/explore/property/_id/index.vue' /* webpackChunkName: "pages/explore/property/_id/index" */))
const _bff9164e = () => interopDefault(import('../pages/explore/property/_id/buildings/index.vue' /* webpackChunkName: "pages/explore/property/_id/buildings/index" */))
const _7b39c986 = () => interopDefault(import('../pages/explore/property/_id/commercial/index.vue' /* webpackChunkName: "pages/explore/property/_id/commercial/index" */))
const _7a018aec = () => interopDefault(import('../pages/explore/property/_id/constructioncases/index.vue' /* webpackChunkName: "pages/explore/property/_id/constructioncases/index" */))
const _a150ffdc = () => interopDefault(import('../pages/explore/property/_id/documents/index.vue' /* webpackChunkName: "pages/explore/property/_id/documents/index" */))
const _a2f0f37e = () => interopDefault(import('../pages/explore/property/_id/easements/index.vue' /* webpackChunkName: "pages/explore/property/_id/easements/index" */))
const _271d91f1 = () => interopDefault(import('../pages/explore/property/_id/mortgages/index.vue' /* webpackChunkName: "pages/explore/property/_id/mortgages/index" */))
const _3e467d96 = () => interopDefault(import('../pages/explore/property/_id/overview/index.vue' /* webpackChunkName: "pages/explore/property/_id/overview/index" */))
const _48805148 = () => interopDefault(import('../pages/explore/property/_id/owners/index.vue' /* webpackChunkName: "pages/explore/property/_id/owners/index" */))
const _25aa6ce3 = () => interopDefault(import('../pages/explore/property/_id/tenants/index.vue' /* webpackChunkName: "pages/explore/property/_id/tenants/index" */))
const _15f46191 = () => interopDefault(import('../pages/explore/property/_id/transactions/index.vue' /* webpackChunkName: "pages/explore/property/_id/transactions/index" */))
const _5d5cc986 = () => interopDefault(import('../pages/explore/reference-properties/_id.vue' /* webpackChunkName: "pages/explore/reference-properties/_id" */))
const _1276d666 = () => interopDefault(import('../pages/download/packaged/_id/_filename.vue' /* webpackChunkName: "pages/download/packaged/_id/_filename" */))
const _2f84a3dd = () => interopDefault(import('../pages/template-builder/_id.vue' /* webpackChunkName: "pages/template-builder/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _2161b8d6,
    name: "auth"
  }, {
    path: "/explore",
    component: _168d2bdc,
    name: "explore"
  }, {
    path: "/login",
    component: _cad79e9c,
    name: "login"
  }, {
    path: "/my-estaid",
    component: _1bcba93d,
    name: "my-estaid",
    children: [{
      path: "lists",
      component: _17d110f4,
      name: "my-estaid-lists"
    }, {
      path: "lists/:id",
      component: _bfecb924,
      name: "my-estaid-lists-id"
    }]
  }, {
    path: "/settings",
    component: _2c20a960,
    name: "settings"
  }, {
    path: "/template-builder",
    component: _de57cf96,
    name: "template-builder"
  }, {
    path: "/explore/advanced",
    component: _50c6f8ea,
    name: "explore-advanced"
  }, {
    path: "/template-builder/template-data",
    component: _a6c170e2,
    name: "template-builder-template-data"
  }, {
    path: "/template-builder/helpers/template-helpers",
    component: _4b037f06,
    name: "template-builder-helpers-template-helpers"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/explore/company/:id?",
    component: _5afd0fb2,
    name: "explore-company-id",
    children: [{
      path: "key-figures",
      component: _68552447,
      name: "explore-company-id-key-figures"
    }, {
      path: "overview",
      component: _acfae12e,
      name: "explore-company-id-overview"
    }, {
      path: "ownership",
      component: _74f9af56,
      name: "explore-company-id-ownership"
    }, {
      path: "portfolio",
      component: _503936ee,
      name: "explore-company-id-portfolio"
    }, {
      path: "stakeholders",
      component: _24862dfd,
      name: "explore-company-id-stakeholders"
    }, {
      path: "transactions",
      component: _5ddff5c5,
      name: "explore-company-id-transactions"
    }]
  }, {
    path: "/explore/person/:id?",
    component: _57c37866,
    children: [{
      path: "",
      component: _4cd19a69,
      name: "explore-person-id"
    }, {
      path: "overview",
      component: _b6a1af96,
      name: "explore-person-id-overview"
    }, {
      path: "ownership",
      component: _2fe9a909,
      name: "explore-person-id-ownership"
    }, {
      path: "relations",
      component: _2a57acde,
      name: "explore-person-id-relations"
    }, {
      path: "transactions",
      component: _4c3b4891,
      name: "explore-person-id-transactions"
    }]
  }, {
    path: "/explore/property/:id?",
    component: _5336ff66,
    children: [{
      path: "",
      component: _0f43e169,
      name: "explore-property-id"
    }, {
      path: "buildings",
      component: _bff9164e,
      name: "explore-property-id-buildings"
    }, {
      path: "commercial",
      component: _7b39c986,
      name: "explore-property-id-commercial"
    }, {
      path: "constructioncases",
      component: _7a018aec,
      name: "explore-property-id-constructioncases"
    }, {
      path: "documents",
      component: _a150ffdc,
      name: "explore-property-id-documents"
    }, {
      path: "easements",
      component: _a2f0f37e,
      name: "explore-property-id-easements"
    }, {
      path: "mortgages",
      component: _271d91f1,
      name: "explore-property-id-mortgages"
    }, {
      path: "overview",
      component: _3e467d96,
      name: "explore-property-id-overview"
    }, {
      path: "owners",
      component: _48805148,
      name: "explore-property-id-owners"
    }, {
      path: "tenants",
      component: _25aa6ce3,
      name: "explore-property-id-tenants"
    }, {
      path: "transactions",
      component: _15f46191,
      name: "explore-property-id-transactions"
    }]
  }, {
    path: "/explore/reference-properties/:id?",
    component: _5d5cc986,
    name: "explore-reference-properties-id"
  }, {
    path: "/download/packaged/:id?/:filename?",
    component: _1276d666,
    name: "download-packaged-id-filename"
  }, {
    path: "/template-builder/:id?",
    component: _2f84a3dd,
    name: "template-builder-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
