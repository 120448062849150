import Vue from "vue";
import moment from "moment";
import { i18n } from "~/plugins/translator";
import { getAddressAsLongString, getAddressAsShortString } from "~/helpers/address-helpers.js";
import { filter } from "~/composables/useFilter";
import { type Address } from "~/graphql/generated/graphql";

Vue.filter("shortdate", filter.date.short);

Vue.filter("longdate", filter.date.long);

Vue.filter("incopenhagen", filter.date.inCopenhagen);

Vue.filter("timefrom", filter.timefrom);

Vue.filter("daterange", filter.date.range);

Vue.filter("momentago", function (date: string) {
  return moment(date).fromNow();
});

Vue.filter("kvm", (v: Nullable<string | number>) => filter.area(v, "m2"));

Vue.filter("phonenumber", function (number: string) {
  let n = number;

  if (n.includes("+")) {
    n = n.replace("+", "");
    return "+" + n.replace(/(.{2})/g, "$1 ");
  }

  return n.replace(/(.{2})/g, "$1 ");
});

Vue.filter("number", filter.number);

Vue.filter("integer", filter.integer);

Vue.filter("percentageDecimal", filter.percentage.decimal);

Vue.filter("percentage", filter.percentage.standard);

Vue.filter("percentageCompact", filter.percentage.compact);

Vue.filter("currency", (number: number | string, currency?: string) => filter.currency(number, { currency }));

Vue.filter("currencyCompact", (number: number | string) => filter.currency(number, { mode: "compact" }));

Vue.filter("currencyKvm", (number: number | string) => filter.currency(number, { mode: "kvm" }));

Vue.filter("uppercase", function (string: string) {
  let s = string || "";

  return s.toUpperCase();
});

Vue.filter("capitalize", function (string: string) {
  let s = string || "";

  return s.replace(/^\p{CWU}/u, (char) => char.toLocaleUpperCase(navigator.language));
});

Vue.filter("lowercase", function (string: string) {
  let s = string || "";

  return s.toLowerCase();
});

Vue.filter("limitCharacters", function (string: string, limit: number) {
  let s = string || "";

  if (s.length > limit) {
    s = s.substring(0, limit);

    if (s[limit - 1] === " ") {
      s = s.substring(0, limit - 1);
    }

    return `${s.substring(0, limit)}…`;
  }

  return s;
});

Vue.filter("translateNullValue", function (input: string | null, translationText: string) {
  if (input === null) {
    return translationText;
  } else {
    return input;
  }
});

Vue.filter("addressLong", function (address: Address | null) {
  return getAddressAsLongString(address) || i18n.t("NO_ACCESS_ADDRESS");
});

Vue.filter("addressShort", function (address: Address | null) {
  return getAddressAsShortString(address) || i18n.t("NO_ACCESS_ADDRESS");
});

Vue.filter("addressStreet", function (address: Address | null) {
  if (address == null) return null;

  let addressString = `${address.streetName} ${address.streetNumber}`;

  return addressString;
});

Vue.filter("addressCity", function (address: Address) {
  return `${address.postalCode} ${address.city}`;
});

Vue.filter("distance", function (distanceInMeters: number | null) {
  if (distanceInMeters == null || isNaN(distanceInMeters) || !isFinite(distanceInMeters)) return "–";

  if (distanceInMeters < 1000) {
    return `${distanceInMeters.toFixed()} ${i18n.t("UNIT_METERS")}`;
  } else {
    let distanceRounded = (distanceInMeters / 1000).toFixed(1);
    return `${distanceRounded} ${i18n.t("UNIT_KILOMETERS")}`;
  }
});

Vue.filter("days", function (days: number | null) {
  if (days == null) return null;

  return `${days} ${i18n.tc("TIMEUNIT_DAY", days)}`;
});

Vue.filter("daysRounded", function (days: number | null) {
  if (days == null) return null;

  const daysRounded = Math.round(days);

  return `${daysRounded} ${i18n.tc("TIMEUNIT_DAY", daysRounded)}`;
});

Vue.filter("string", filter.string);

Vue.filter("quarter", filter.quarter);

Vue.filter("month", filter.month);

Vue.filter("year", filter.year);
